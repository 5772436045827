<template>
  <loader v-if="showloader == true"></loader>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            class="form-control form-control-solid w-250px ps-15"
            placeholder="Recherche"
          />
        </div>
      </div>
      <div class="card-toolbar">
        <div
          class="d-flex justify-content-end"
          data-kt-customer-table-toolbar="base"
        >
          <!-- <button type="button" class="btn btn-light-primary me-3">
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotune/arrows/arr078.svg" />
            </span>
            Export
          </button> -->
          <button
            type="button"
            class="btn btn-primary btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_promotion"
            style="float: right"
            @click="maj('', 'ajouter')"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotone/Navigation/Plus.svg" />
            </span>
            Ajouter
          </button>
        </div>
      </div>
    </div>

    <div class="card-body pt-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="table-responsive">
          <table
             class="table table-hover table-rounded table-striped border gy-7 gs-7"
          >
            <thead>
              <tr
                class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 text-uppercase"
              >
                <th>#</th>
                <th>Nom <i class="fa fa-sort" @click="sort('name')" style="cursor:pointer !important;"></i></th>
                <th>Remise <i class="fa fa-sort" @click="sort('remise')" style="cursor:pointer !important;"></i></th>
                <th>Date debut <i class="fa fa-sort" @click="sort('datedebut')" style="cursor:pointer !important;"></i></th>
                <th>Date fin <i class="fa fa-sort" @click="sort('datefin')" style="cursor:pointer !important;"></i></th>
                <th>date de creation <i class="fa fa-sort" @click="sort('created_at')" style="cursor:pointer !important;"></i></th>                
                <th>Offre</th>
                <th colspan="2">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in ListePromotion" :key="item.id">
                <td class="odd">{{ index + 1 }}</td>
                <td class="odd">{{ item.name }}</td>
                <td class="odd">{{ item.remise }} %</td>
                <td class="odd">{{ date_format(item.datedebut) }}</td>
                <td class="odd">{{ date_format(item.datefin) }}</td>
                <td class="odd">{{ date_format(item.created_at) }}</td>
                <td>
                  <span v-for="o in item.offre" :key="o.id">
                    <p>{{o.name}}</p>
                  </span>
                </td>
                <td class="odd" v-if="item.isActive == 0">
                  <i
                    class="fa fa-ban"
                    aria-hidden="true"
                    style="color: red; font-weight: bold; font-size: 18px"
                  ></i>
                </td>
                <td class="odd" v-if="item.isActive == 1">
                  <i
                    class="fa fa-check"
                    aria-hidden="true"
                    style="color: #50cd89; font-weight: bold; font-size: 18px"
                  ></i>
                </td>
                <td class="end">
                  <span class="action_icon"
                    ><i
                      class="fa fa-edit fa-2x"
                      @click="maj(item, 'editer')"
                      style="cursor: pointer"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_promotion"
                    ></i
                    >Edit</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- MAJ PROMOTION -->
  <div class="modal fade" tabindex="-1" id="kt_modal_promotion">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ formMode }} Promotion</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="resetData()"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
          <!--end::Close-->
        </div>
        <!--Start Body modal add user-->
        <div class="modal-body">
          <form class="row g-3">
            <div class="row">
              <div class="col-md-6 padding_left_none">
                <label for="name" class="form-label required">Nom </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  class="form-control m-2"
                  v-model="add_data.name"
                />
              </div>
              <div class="col-md-6">
                <label for="remise" class="form-label">Remise (%) </label>
                <input
                  type="number"
                  id="remise"
                  name="remise"
                  class="form-control m-2"
                  min="1"
                  v-model="add_data.remise"
                />
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label for="daterange" class="form-label required"
                    >Date debut et fin
                  </label>
                  <el-date-picker
                    class="form-control m-3"
                    type="daterange"
                    v-model="add_data.dateRange"
                  >
                  </el-date-picker>
                </div>
                
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label for="isActive" class="form-label required"
                    >Status
                  </label>
                  <select class="form-select m-2" v-model="add_data.isActive">
                    <option value="1">Activer</option>
                    <option value="0">Desactiver</option>
                  </select>
                </div>
                <div class="col-md-6">
                </div>
              </div>
              <div class="row">
                  <div class="col-md-12 padding_left_none">
                    <label for="offre" class="form-label required">Offre</label>
                    <Multiselect
                        v-model="selectOffre"
                        mode="tags"
                        :object="true"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="true"
                        :options="ListeOffre"
                        label="name"
                        trackBy="name"
                    />
                  </div>
              </div>
            </div>
          </form>
        </div>
        <!--End Body modal add user-->
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="resetData()"
          >
            Fermer
          </button>
          <button type="button" class="btn btn-primary" @click="submit()">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- fin -->
</template>

<script>
import { ApiService } from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Multiselect from "@vueform/multiselect";
import moment from "moment";
import loader from "./utility/loader.vue";

export default {
  components: {
    Multiselect,
    loader
  },
  data() {
    return {
      ListePromotion: {},
      ListeOffre: {},
      add_data: {
        isActive: 1,
        id: 0,
        dateRange:[]
      },
      formMode: "ajouter",
      showloader: false,
      selectOffre: [],
      orderStatus: "asc",
    };
  },
  methods: {
    sort(index) {
      if (this.orderStatus === 'asc') {
          this.orderStatus = 'desc';
          this.ListePromotion = this.ListePromotion.sort((a, b) => {
            return b[index].localeCompare(a[index]);
          });
      } else if (this.orderStatus === 'desc') {
          this.orderStatus = 'asc';
          this.ListePromotion = this.ListePromotion.sort((a, b) => {
            return a[index].localeCompare(b[index]);
          });
      };
    },
    getListePromotion() {
      this.showloader = true;
      ApiService.setHeader();
      ApiService.get("promotion/Liste")
        .then(async (result) => {  
          if(result.data){
              this.ListePromotion = await result.data;
              this.getAllOffreDefault();
              this.showloader = false;
          }       
        })
        .catch((err) => {
          console.log(err);
        });
    },
    
    getAllOffreDefault() {      
      ApiService.setHeader();
      ApiService.get("promotion/offreDefault")
        .then(async (result) => {   
          if(result.data){
              this.ListeOffre = await result.data;
              this.traitement_offre();
          }      
                    
        })
        .catch((err) => {
          console.log(err);
        });
    },

    traitement_offre() {
      this.ListeOffre.forEach((item, index) => {
        this.ListeOffre[index]["value"] = item.id;
      });
    },

    async submit() {
      let db="";
      let df="";
      if(this.add_data.dateRange){
          this.showloader = true;
          db = await this.add_data.dateRange[0];
          df = await this.add_data.dateRange[1];

          this.add_data["datedebut"] = db;
          this.add_data["datefin"] = df;
      }

      const data_select_tmp = [];
      this.selectOffre.forEach(async (item, index) => {
        await data_select_tmp.push(item.id);
      });
      this.add_data["listeIdOffre"] = data_select_tmp;

      this.add_data["formMode"] = this.formMode;

      ApiService.setHeader();
      ApiService.post("promotion/InsertOrUpdate", this.add_data)
        .then((response) => {
          this.showloader = false;
          this.alertSucess();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async maj(item, val) {
      this.formMode = val;
      this.selectOffre = [];
      if (val == "editer") {
        const data_tmp = JSON.parse(JSON.stringify(item));
        data_tmp["dateRange"] = [new Date(item.datedebut), new Date(item.datefin)];
        this.add_data = data_tmp;
        this.traitement_select_offre(item.offre);
      } else {
        this.add_data = {
          isActive: 1,
          id: 0,
          dateRange:[]
        };
      }
    },
    traitement_select_offre(data){
        data.forEach(async (item, index) => {
          data[index]["value"] = await item.id;
          this.selectOffre.push(item);
        });
    },
    resetData() {
      this.add_data = {
        isActive: 1,
        id: 0,
        dateRange:[]
      };
      this.selectOffre = [];
    },

    date_format(value) {
      return moment(String(value)).format("DD/MM/YYYY hh:mm");
    },

    alertSucess() {
      Swal.fire({
        text: "Enregistrer avec succes!",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(function () {
        location.reload();
      });
    },
  },
  mounted() {
    this.getListePromotion();
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
